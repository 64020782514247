import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

import { Apollo } from 'apollo-angular';
import { 
  REVOKE_GOOGLE_API_CONSENT
} from './auth-http/auth-graphql.service'

// export type UserType = UserModel | undefined;
export type UserType = User | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private userLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private apollo: Apollo
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  logout() {
    if (this.currentUserValue && this.currentUserValue?.id) {
      console.log('Revoking access ...');
      
      // Revoke Google API consent
      // Call API to revoke Google API access permission via GraphQL query
      // - see auth-http/auth-graphql.service.ts
      this.apollo.mutate({
        mutation: REVOKE_GOOGLE_API_CONSENT,
        variables: {
          requestBody: {
            identityId: this.currentUserValue?.id
          }
        },
        fetchPolicy: 'no-cache'
      }).subscribe(({data}: any) => {
        console.log('Google API consent has been revoked successfully');
        // Remove user data from local storage 
        localStorage.removeItem(this.userLocalStorageToken);
        // Remove prompt model from local storage
        // TODO: Use versionized key
        localStorage.removeItem('v0.1.0-promptModel');
        // Redirect to login page
        location.href = '/auth/login';
      }, (error) => {
        console.log('there was an error sending the query', error);
      });
    } else {
      location.href = '/auth/login';
    }
  }

  getUserByToken(): Observable<UserType> {
    const user = this.getUserFromLocalStorage();
    if (!user || !user.id) {
      return of(user);
    } else {
      this.currentUserSubject.next(user);
      return of(user);
    }
  }

  public setUserToLocalStorage(user: User): boolean {
    // store user details and jwt authToken in local storage to keep user logged in between page refreshes
    if (user && user.id) {
      localStorage.setItem(this.userLocalStorageToken, JSON.stringify(user));
      return true;
    }
    return false;
  }

  private getUserFromLocalStorage(): User | undefined {
    try {
      const lsValue = localStorage.getItem(this.userLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const userData = JSON.parse(lsValue);
      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
