// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '0.2.0-beta.2',
  USERDATA_KEY: 'userf649fc9a5f55',
  isMockEnabled: true,
  // configFilename: 'config.dev',
  webApiUrl: 'https://aether-app-service-test.azurewebsites.net/graphql',
  workspaceApiUrl: 'https://aether-workspace-service-test.azurewebsites.net/workspace',
  googleOAuth2: {
    clientId: '318039172465-qlqjaoj3gd7ilp8d05s92962vjp09a7t.apps.googleusercontent.com',
  },
  googleBusinessProfile: {
    name: 'google-business-profile',
    url: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId: '482210749089-lfed5ggd7bnhjfjcj460dqi9a4kv59v3.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/business.manage',
    accessType: 'offline',
    includeGrantedScopes: 'true',
    responseType: 'code',
    redirectUri: 'https://aether-test.aitherworks.com/dashboard/google-callback'
  },
  googleSheet: {
    name: 'google-sheet',
    url: 'https://accounts.google.com/o/oauth2/v2/auth',
    clientId: '318039172465-g43efpn4ckmsk4s62esulbe79qm4866n.apps.googleusercontent.com',
    scope: 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets.readonly',
    accessType: 'offline',
    includeGrantedScopes: 'true',
    responseType: 'code',
    redirectUri: 'https://aether-test.aitherworks.com/workspace/google-sheet-callback'
  },
  linkedIn: {
    name: 'authorization-code-flow'
  },
  apiUrl: 'api',
  connector: {
    googlePlace: {
      enabled: false,
      allowedUsers: ['ryan@engagecraft.ai', 'stanley@engagecraft.ai', 'ryan.kschee@gmail.com']
    },
    google: {
      enabled: true,
      averageRating: {
        suggestion: {
          notes: {
            enabled: true
          },
          actions: {
            enabled: true
          }
        }
      },
      nearbyAnalysis: {
        enabled: false
      },
      localPost: {
        enabled: false
      }
    },
    facebook: {
      enabled: true,
      appId: '631130695069606',
      appVersion: 'v17.0'
    }
  },
  module: {
    login: {
      enabled: true
    },
    dashboard: {
      enabled: false
    },
    marketing: {
      enabled: false
    },
    workspace: {
      enabled: true,
      works: {
        digitalMarketing: {
          enabled: false
        },
        personalFinance: {
          enabled: true
        },
        travelLeisure: {
          enabled: true
        }
      }
    }
  },
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  appDemos: {
    'demo1': {
      'title': 'Demo 1',
      'description': 'Default Dashboard',
      'published': true,
      'thumbnail': './assets/media/demos/demo1.png'
    },

    'demo2': {
      'title': 'Demo 2',
      'description': 'SaaS Application',
      'published': true,
      'thumbnail': './assets/media/demos/demo2.png'
    },

    'demo3': {
      'title': 'Demo 3',
      'description': 'New Trend',
      'published': true,
      'thumbnail': './assets/media/demos/demo3.png'
    },

    'demo4': {
      'title': 'Demo 4',
      'description': 'Intranet Application',
      'published': true,
      'thumbnail': './assets/media/demos/demo4.png'
    },

    'demo5': {
      'title': 'Demo 5',
      'description': 'Support Forum',
      'published': false,
      'thumbnail': './assets/media/demos/demo5.png'
    },

    'demo6': {
      'title': 'Demo 6',
      'description': 'Admin Backend',
      'published': true,
      'thumbnail': './assets/media/demos/demo6.png'
    },

    'demo7': {
      'title': 'Demo 7',
      'description': 'CRM Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo7.png'
    },

    'demo8': {
      'title': 'Demo 8',
      'description': 'Core Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo8.png'
    },

    'demo9': {
      'title': 'Demo 9',
      'description': 'Fancy Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo9.png'
    },

    'demo10': {
      'title': 'Demo 10',
      'description': 'Modern Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo10.png'
    },

    'demo11': {
      'title': 'Demo 11',
      'description': 'Light Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo11.png'
    },

    'demo12': {
      'title': 'Demo 12',
      'description': 'Reporting System',
      'published': false,
      'thumbnail': './assets/media/demos/demo12.png'
    },

    'demo13': {
      'title': 'Demo 13',
      'description': 'Classic Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo13.png'
    },

    'demo14': {
      'title': 'Demo 14',
      'description': 'Creative Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo14.png'
    },

    'demo15': {
      'title': 'Demo 15',
      'description': 'Minimalistic Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo15.png'
    },

    'demo16': {
      'title': 'Demo 16',
      'description': 'Modern Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo16.png'
    },

    'demo17': {
      'title': 'Demo 17',
      'description': 'Backend System',
      'published': false,
      'thumbnail': './assets/media/demos/demo17.png'
    },

    'demo18': {
      'title': 'Demo 18',
      'description': 'System Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo18.png'
    },

    'demo19': {
      'title': 'Demo 19',
      'description': 'Light Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo19.png'
    },

    'demo20': {
      'title': 'Demo 20',
      'description': 'Monochrome Dashboard',
      'published': false,
      'thumbnail': './assets/media/demos/demo20.png'
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
