import { gql } from 'apollo-angular';

const GET_IDENTITY_PERMISSIONS = gql`
    query GetIdentityPermissions($identityId: String!) {
        identity: identityById(id: $identityId) {
            id
            version
            createdAt
            updatedAt
            permissions {
                provider
                status
                userId
            }
        }
    }`;

const REVOKE_GOOGLE_API_CONSENT = gql`
    mutation RevokeGoogleApiConsent($requestBody: GoogleAuthRequest!) {
        identity: revokeGoogleApiConsent(request: $requestBody) {
            id
            version
            createdAt
            updatedAt
            accounts {
                id
                name
                email
                thumbnail
                provider
                credential {
                    expireAt
                }
            }
            permissions {
                provider
                status
                userId
            }
        }
    }`;

export { 
    GET_IDENTITY_PERMISSIONS,  
    REVOKE_GOOGLE_API_CONSENT, 
};