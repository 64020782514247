import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoader {
  
  private config: any;

  constructor() {}

  getConfig(): any {
    const configObj = { ...environment, ...this.config };
    return configObj;
  }
}
