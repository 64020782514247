import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { ConfigLoader } from './services/config-loader.service';

export function createApollo(httpLink: HttpLink, configLoader: ConfigLoader): ApolloClientOptions<any> {
  const config = configLoader.getConfig(); // Get the configuration object
  const uri = config.webApiUrl; // Get the URI from the configuration object
  
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigLoader],
    },
  ],
})
export class GraphQLModule {}
